import React, { useContext, useMemo } from "react";
import { MapPinSvg } from "../../../assets/svg";
import { COLORS } from "../../../assets/Theme";
import PackageSvg from "../../../assets/svg/PackageSvg";
import ChevronRightSvg from "../../../assets/svg/ChevronRightSvg";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Context } from "../../../context/StorageContext";
import TrackingServices from "../../../services/TrackingServices";
import moment from "moment";
import { useLocation } from "react-router-dom";

const PackageJourney = ({ showFull, handleViewJourney, disabled }) => {
  const { trackingData } = useContext(Context);
  const path = useLocation();

  const { data } = useQuery({
    queryKey: ["journery", trackingData?.id],
    queryFn: () => {
      if (trackingData?.category === "agent") {
        return TrackingServices.getPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      } else if (trackingData?.category === "doorstep") {
        return TrackingServices.getDoorstepPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      } else if (
        trackingData?.category === "courier" ||
        trackingData?.category === "errand"
      ) {
        return TrackingServices.getErrandPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      } else if (trackingData?.category === "shelf") {
        return TrackingServices.getShelfPackageNarrations(
          trackingData?.id,
          path?.pathname
        );
      }
    },
    onSuccess: (res) => {},
    onError: (err) => {
      toast.error(
        "Package fetch error. " + err?.response?.data?.message ?? err?.message
      );
    },
    refetchInterval: 30000,
  });

  const pkgJourney = useMemo(() => {
    if (!showFull) return data?.descriptions?.slice(0, 4);
    else return data?.descriptions;
  }, [data, showFull]);

  return (
    <div
      className={
        "p-4 rounded-2xl bg-white border border-gray-200 w-full flex gap-x-3 items-center"
      }
    >
      <div className={"flex-1"}>
        {!showFull && (
          <div className={"flex gap-x-2 items-center"}>
            <h5 className={"font-bold text-base"}>Package Journey</h5>
          </div>
        )}

        <div className={`flex flex-col ${!showFull && "pt-3"}`}>
          {pkgJourney?.map((item, index) => (
            <JourneyItem
              key={index}
              isFirst={index === 0}
              isChecked={true}
              isLast={item?.state === "collected"}
              desc={item?.desc ?? item?.descriptions}
              date={moment(item?.time).format("HH:MM, DD-MMM-YYYY")}
            />
          ))}
        </div>

        {/*    view journey button   */}
        {!showFull && (
          <button
            className={`font-semibold text-sm p-2.5 w-full hover:bg-gray-100 rounded-lg flex ${
              disabled && "cursor-not-allowed"
            } items-center justify-between`}
            onClick={handleViewJourney}
            disabled={disabled}
          >
            <span>View Journey</span>

            <ChevronRightSvg />
          </button>
        )}
      </div>
    </div>
  );
};

export default PackageJourney;

const journey = [
  "Pkg PMT-GW2-5032 created by Sweety Sweets",
  "Drop off confirmed by GreatWall Gardens 2 The Corner Hub waiting for rider to collect.",
  "Drop off confirmed by GreatWall Gardens 2 The Corner Hub waiting for rider to collect.",
  "Rider on his way to deliver the  package to Aigle Pharmacy",
];

const JourneyItem = ({ isChecked, isLast, isFirst, date, desc }) => (
  <div className={"flex gap-x-4"}>
    {/*  icon & line  */}
    <div className={"flex-1 w-5 flex justify-center relative"}>
      <div
        className={`${isLast ? "h-1/2" : "h-full"} border border-dashed ${
          isChecked ? "border-primary" : "border-gray-400"
        }`}
      />

      <div
        className={`h-6 w-6 rounded-full flex items-center justify-center ${
          isChecked ? "bg-primary_100" : "bg-gray-200"
        } absolute ${isLast ? "top-[25%]" : isFirst ? "top-0" : "top-[25%]"}`}
      >
        {isLast ? (
          <MapPinSvg
            h={"18"}
            w={"17"}
            color={isChecked ? COLORS.primary : "#98A2B3"}
          />
        ) : isFirst ? (
          <PackageSvg color={isChecked ? COLORS.primary : "#98A2B3"} h={"18"} />
        ) : (
          <div
            className={`h-3.5 w-3.5 rounded-full border-[2px] ${
              isChecked ? "border-primary" : "border-gray-400"
            }`}
          />
        )}
      </div>
    </div>

    <div className={"pb-3 w-full"}>
      {/*  date  */}
      <h6 className={"text-[13px] text-gray-500 font-normal mb-1"}>{date}</h6>
      <p className={"text-[13px] line-clamp-3"}>{desc}</p>
    </div>
  </div>
);
