import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useMap } from 'react-leaflet/hooks';
import 'leaflet-defaulticon-compatibility';
import { Icon, divIcon } from 'leaflet';
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import Helper from "../../utils/Helper";
import TrackingServices from "../../services/TrackingServices";
import { Context } from "../../context/StorageContext";
import MinusSvg from "../../assets/svg/MinusSvg";
import PlusSvg from "../../assets/svg/PlusSvg";

/**
 * @since X.X.X
 * @Link https://pickupmtaani.atlassian.net/jira/software/c/projects/DEV/issues/DEV-279
 *
 * @param {DeliveryPackage} pkg
 * @return {JSX.Element}
 * @desc renders tracker map
 */
const Map = ({ pkgDetails }) => {

    const { search } = useLocation();
    const trackingId = search.split("?")?.at(1);
    const { setTrackingData, zoom } = useContext(Context);
    const path = useLocation();


    const { data } = useQuery({
        queryKey: ["todos", trackingId],
        queryFn: () => TrackingServices.trackPackage(trackingId, path.pathname),
        onSuccess: (res) => {
            setTrackingData(res.packageDetails);
        },
        onError: (err) => {
            toast.error(
                "Tracking error. " + err.response.data?.message || err.message
            );
        },
        retry: false,
        refetchInterval: 10000,
    });

    const pkg = data?.packageDetails;
    const navigate = useNavigate();

    const currentAgent = useMemo(() => {
        return Helper.getCurrentAgent(pkg?.status, pkgDetails);
    }, [pkg?.status, pkgDetails]);

    // gets package tracking state(agent/rider/warehouse)
    const packageState = useMemo(
        () => Helper.getCurrentStage(pkg?.status),
        [pkg?.status]
    );

    const center = useMemo(
        () => ({
            lat: -1.2878412,
            lng: 36.8278173,
        }),
        []
    );

    const options = useMemo(
        () => ({
            //   mapId: "b181cac70f27f5e6",
            disableDefaultUI: true,
            clickableIcons: false,
            // mapId: "f2dbb0573bbff1b1",
        }),
        []
    );

    const riderIcon = new divIcon({
        html: '<div><img src="/images/RIDER.png" class="rider-movement"/></div>'
    })

    const warehouseIcon = new Icon({
        iconUrl: '/images/marker.png',
        iconSize: [50, 65], // size of the icon
        popupAnchor: [0, -40]
    })

    const agentIcon = new Icon({
        iconUrl: '/images/marker.png',
        iconSize: [50, 65],  // size of the icon
        popupAnchor: [0, -40]
    })

    const shelfIcon = new Icon({
        iconUrl: '/images/marker.png',
        iconSize: [50, 65],
        popupAnchor: [0, -40]
    })

    const defaultIcon = new Icon({
        iconUrl: '/images/marker.png',
        iconSize: [50, 65],
        popupAnchor: [0, -40]
    })

    const theIcon = (packageState) => {
        if (!packageState) {
            return defaultIcon;
        }
        if (packageState === "rider") {
            return riderIcon;
        } else if (packageState === "warehouse") {
            return warehouseIcon;
        } else if (packageState.trim() === "agent") {
            return agentIcon;
        } else {
            return defaultIcon;
        }
    }

    const RecenterAutomatically = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => {
            if (lat) {
                try {
                    map.setView([lat, lng], map.getZoom(), { animated: true, duration: 1 });
                } catch (error) {
                    console.log(error)
                }
            } else {
                //console.log(lat, lng)
            }
        }, [lat, lng]);
        return null;
    }


    const ZoomBtnAdd = ({ children, onClick }) => {
        const map = useMap();
        return (
            <button
                className={
                    "h-14 w-14 bg-white rounded-xl shadow flex items-center justify-center hover:bg-gray-50"
                }
                style={{ zIndex: '999' }}
                onClick={() => map.setZoom(map.getZoom() + 1)}
            >
                {children}
            </button>
        )
    };

    const ZoomBtnMinus = ({ children, onClick }) => {
        const map = useMap();
        return (
            <button
                className={
                    "h-14 w-14 bg-white rounded-xl shadow flex items-center justify-center hover:bg-gray-50"
                }
                style={{ zIndex: '999' }}
                onClick={() => map.setZoom(map.getZoom() - 1)}
            >
                {children}
            </button>
        )
    };

    return (
        <MapContainer
            style={{
                width: "100%",
                'z-index': 0
            }}
            center={center}
            zoom={zoom}
            zoomControl={false}
            options={options}
            className="map-container h-[43vh] md:h-screen "
            heading={parseInt(pkg?.location?.heading) || 0}
        >
            <RecenterAutomatically lat={pkg?.location?.lat} lng={pkg?.location?.lng} />
            {/* add google map tile url  */}
            {/*{Object.values(coordinates)?.map((c) => (*/}
            {packageState && pkg?.location?.lat && pkg?.location?.lng ? ( // added a check for the packages without geo-coordinates
                <Marker
                    position={{
                        lat: Number(pkg?.location?.lat),
                        lng: Number(pkg?.location?.lng),
                    }}
                    icon={theIcon(packageState)}
                    style={{
                        border: '1px solid black'
                    }}
                >

                    <Popup
                        position={{
                            lat: Number(pkg?.location?.lat),
                            lng: Number(pkg?.location?.lng),
                        }}
                    >
                        <div
                            style={{
                                opacity: 0.75,
                                padding: 3,
                            }}
                        >
                            <div>
                                <span className="font-bold">{currentAgent?.business_name}</span>

                                <h6 className=" mt-2">{currentAgent?.agent_locations?.name}</h6>
                                <div className={"mt-2"}>
                                    <button
                                        onClick={() => navigate(`/agent/${currentAgent?.id}`)}
                                        className="py-1 px-2 justify-center bg-slate-500 text-white rounded-sm hover:scale-[0.995] hover:text-xs hover:font-semibold duration-300"
                                    >
                                        View
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Popup>

                </Marker>
            ) : (
                pkg?.category === "shelf" && (
                    <Marker
                        position={{
                            lat: pkg?.location?.lat,
                            lng: pkg?.location?.lng,
                        }}
                        icon={theIcon(packageState)}
                    >

                        <Popup
                            position={{
                                lat: Number(pkg?.location?.lat),
                                lng: Number(pkg?.location?.lng),
                            }}
                        >
                            <div
                                style={{
                                    opacity: 0.75,
                                    padding: 3,
                                }}
                            >
                                <div>
                                    <span className="font-bold">{currentAgent?.business_name}</span>

                                    <h6 className=" mt-2">{currentAgent?.agent_locations?.name}</h6>
                                    <div className={"mt-2"}>
                                        <button
                                            onClick={() => navigate(`/agent/${currentAgent?.id}`)}
                                            className="py-1 px-2 justify-center bg-slate-500 text-white rounded-sm hover:scale-[0.995] hover:text-xs hover:font-semibold duration-300"
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Popup>

                    </Marker>
                )
            )}
            {/*))}*/}
            <TileLayer
                attribution="Google Maps"
                url="https://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />

            <div
                className={"p-1 m-2 rounded-xl flex flex-col gap-y-2 shadow-lg bg-gray-50"}
                style={{ position: 'absolute', zIndex: '999', left: 0, bottom: 0 }}
            >
                {/*  + button  */}
                <ZoomBtnAdd onClick={1}>
                    <PlusSvg />
                </ZoomBtnAdd>
                {/*    - Button   */}
                <ZoomBtnMinus onClick={1}>
                    <MinusSvg />
                </ZoomBtnMinus>
            </div>
        </MapContainer>
    );
}

export default Map;